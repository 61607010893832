.like {
    $this: &;

    align-self: flex-end;
    color: $color-darker-grey;
    transition: color 0.3s ease;

    &__count {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 77px;
        height: 84px;
        cursor: pointer;

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__value {
        transform: translateY(10px);
        font-family: $ff-pangolin;
        font-size: 18px;
        pointer-events: none;
    }

    path.line {
        opacity: 0;
        color: $color-darker-grey;
        transition: color 0.3s ease, opacity 0.3s ease;
    }

    &.is-liked {
        color: $color-red;

        path.line {
            opacity: 1;
            color: $color-red;
        }
    }
}
