$breakpoints: (
    'tablet': 768px,
    'desktop': 1024px,
    'desktop-wide': 1280px,
);

@each $size, $value in $breakpoints {
    @custom-media --#{$size} (width >= #{$value});
}

@custom-media --only-mobile (width < #{map-get($breakpoints, tablet)});
@custom-media --only-tablet (width >= #{map-get($breakpoints, tablet)}) and (width < #{map-get($breakpoints, desktop)});
@custom-media --only-desktop (width >= #{map-get($breakpoints, desktop)}) and (width < #{map-get($breakpoints, desktop-wide)});

@mixin mobile-only {
    @media (--only-mobile) {
        @content;
    }
}

@mixin tablet {
    @media (--tablet) {
        @content;
    }
}

@mixin tablet-only {
    @media (--only-tablet) {
        @content;
    }
}

@mixin until-desktop {
    @media (--until-desktop) {
        @content;
    }
}

@mixin desktop {
    @media (--desktop) {
        @content;
    }
}

@mixin desktop-wide {
    @media (--desktop-wide) {
        @content;
    }
}
