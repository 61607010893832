.modal {
    $this: &;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 500;
    display: none;
    flex-direction: column;
    align-items: center;
    background: rgba(12, 7, 9, 0.9);
    backdrop-filter: blur(20px);

    &__head {
        display: none;
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex: 1 0 auto;
        width: 100%;
        min-height: 100vh;
        margin: auto;
        padding: 20px;
    }

    &__content {
        position: relative;
        width: 100%;
    }

    &__close-icon {
        @include tablet {
            width: 16px;
            height: 16px;
        }
    }

    &__image {
        position: relative;
        display: block;
        width: 100%;
        min-height: 300px;
        max-height: calc(100vh - 120px);
        background: svg-load('../../general/svg/image.svg', fill=rgba($color-white, 0.5)) no-repeat
            center;
        background-size: 10%;
        border: 10px solid $color-white;
        border-radius: 10px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        @include desktop {
            min-height: 400px;
        }

        &.lazyload,
        &.lazyloading {
            opacity: 1;
        }

        &.lazyloaded {
            background: none;
        }
    }

    &--image {
        #{$this}__wrapper {
            max-width: 890px;
            min-height: 0;
            max-height: calc(100% - 60px);
            margin: 0 auto;
            padding: 30px;

            @include tablet {
                padding: 60px;
            }
        }

        #{$this}__head {
            display: flex;
        }

        #{$this}__close {
            position: absolute;
            right: 0;
            bottom: calc(100% + 4px);
            color: $color-white;
        }
    }

    &.is-opened {
        display: block;
    }
}
