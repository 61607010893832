/* general mixins and helpers */

@mixin reset-list() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset-button() {
    background-color: transparent;
    border: none;
}

@mixin objectFitImageParent($fit: cover) {
    img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: $fit;

        @content;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin hover-focus() {
    .can-hover &:hover,
    [data-whatintent='keyboard'] &:focus {
        @content;
    }
}

@mixin visually-hidden() {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
}
