.form-input {
    $this: &;

    position: relative;
    display: block;
    width: 100%;

    &__control {
        width: 100%;
        padding: 15px 10px;
        background-color: $color-light-grey;
        border: 1px solid $color-light-grey-2;
        border-radius: 5px;
        color: $color-dark-grey;
        font-family: $ff-fira-code;
        font-size: 12px;
        line-height: 1;
        appearance: none;

        @include placeholder {
            color: $color-dark-grey;
        }
    }
}
