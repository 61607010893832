/* Typography */

@mixin h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;

    @include tablet {
        font-size: 80px;
        line-height: 0.94;
    }
}

@mixin h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;

    @include tablet {
        font-size: 36px;
    }
}

@mixin h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.33;

    @include tablet {
        font-size: 24px;
        line-height: 1.25;
    }
}

@mixin h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.33;

    @include tablet {
        padding-top: 20px;
        font-size: 24px;
        line-height: 1.25;
    }
}

@mixin h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;

    @include tablet {
        font-size: 18px;
        line-height: 1.33;
    }
}

@mixin h6 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.67;
}
