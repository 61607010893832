/* general grid styles */
$grid-columns: 12;
$grid-gutter: 30px;
$grid-gutter-mobile: 15px;

@mixin col {
    box-sizing: border-box;
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 1px;

    &.no-gutter {
        padding: 0;
    }
}

.row {
    $this: &;

    display: flex;
    flex-wrap: wrap;

    @include tablet {
        margin-right: -$grid-gutter / 2;
        margin-left: -$grid-gutter / 2;
    }

    &--center {
        justify-content: center;
    }

    &--vcenter {
        align-items: center;
    }

    &--tiles:not(.no-gutter) {
        margin-bottom: -$grid-gutter-mobile;

        @include desktop {
            margin-bottom: -$grid-gutter;
        }

        > [class*='col-'] {
            margin-bottom: 55px;
        }
    }

    &--general {
        > [class*='col-'] {
            + [class*='col-'] {
                margin-top: 30px;

                @include desktop {
                    margin-top: 0;
                }
            }
        }
    }

    &.no-gutter {
        margin-right: 0;
        margin-left: 0;

        > [class*='col-'] {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.no-stacked-col-gap {
        > [class*='col-'] + [class*='col-'] {
            margin: 0;
        }
    }

    > [class*='col-'] {
        @include col();

        @include tablet {
            padding-right: $grid-gutter / 2;
            padding-left: $grid-gutter / 2;
        }
    }
}

@mixin grid($containerColumnsCount, $gutter, $breakpoints) {
    $maxColWidth: 100 / $containerColumnsCount;

    @for $i from 1 through $containerColumnsCount {
        .row > .col-#{$i} {
            flex-basis: $maxColWidth * $i + 0%;
            max-width: $maxColWidth * $i + 0%;
        }
    }

    @each $size, $value in $breakpoints {
        @media only screen and (min-width: $value) {
            @for $column from 1 through $containerColumnsCount {
                .row > .col-#{$size}-#{$column} {
                    flex-basis: $maxColWidth * $column + 0%;
                    max-width: $maxColWidth * $column + 0%;
                }
            }
        }
    }
}

@include grid($grid-columns, $grid-gutter, $breakpoints);
