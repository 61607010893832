.form-picker-radio {
    $this: &;

    display: inline-flex;

    &__label {
        position: relative;
        display: inline-flex;
        align-items: center;
    }

    &__tick {
        position: relative;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background-color: $color-green;
        border-radius: 50%;
        cursor: pointer;

        &::after {
            content: none;
            position: absolute;
            width: 40px;
            height: 40px;
            border: 1px solid $color-green;
            border-radius: 50%;
        }
    }

    &__text {
        margin-left: 8px;
        cursor: pointer;

        @include tablet {
            font-size: 14px;
        }
    }

    &__control {
        @include visually-hidden();

        &:checked {
            & ~ #{$this}__tick {
                &::after {
                    content: '';
                }
            }
        }
    }

    & + & {
        margin-left: 16px;
    }

    &--green {
        #{$this}__tick {
            background-color: $color-green;

            &::after {
                border-color: $color-green;
            }
        }
    }

    &--yellow {
        #{$this}__tick {
            background-color: $color-yellow-3;

            &::after {
                border-color: $color-yellow-3;
            }
        }
    }

    &--pink {
        #{$this}__tick {
            background-color: $color-pink;

            &::after {
                border-color: $color-pink;
            }
        }
    }

    &--blue {
        #{$this}__tick {
            background-color: $color-blue-3;

            &::after {
                border-color: $color-blue-3;
            }
        }
    }
}
