.form-textarea {
    $this: &;

    position: relative;
    display: block;

    &__control {
        width: 100%;
        min-height: 90px;
        padding: 15px 10px;
        background-color: $color-light-grey;
        border: 1px solid $color-light-grey-2;
        border-radius: 5px;
        color: $color-dark-grey;
        font-family: $ff-fira-code;
        font-size: 12px;
        line-height: 1.33;
        resize: vertical;
        appearance: none;

        @include placeholder {
            color: $color-dark-grey;
        }
    }
}
