.btn,
.btn-primary {
    @include reset-button();

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: $ff-fira-code;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    outline: none;

    .icon {
        flex-shrink: 0;
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;
    }
}

.btn-primary {
    padding: 12px 24px;
    border: 2px solid transparent;
    color: currentColor;

    &--with-icon {
        & > :first-child {
            margin-right: 10px;
        }
    }

    &--yellow {
        background-color: $color-yellow-2;
        border-radius: 30px;
        box-shadow: 0 4px 4px rgba($color-black, 0.3);
        color: $color-black;
    }

    &--transparent {
        border: 2px solid $color-white;
        border-radius: 30px;
        color: $color-white;
    }

    &--outlined-blue {
        border: 1px solid $color-blue;
        border-radius: 30px;
        color: $color-blue-2;
    }
}
