.form {
    $this: &;

    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 440px;
    margin: 0 auto;
    padding: 30px;
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__title {
        font-family: $ff-caveat;
        font-size: 26px;
        font-weight: 700;

        @include tablet {
            font-size: 36px;
        }
    }

    &__group {
        & + & {
            margin-top: 30px;
        }
    }

    &__close-btn {
        margin-left: 20px;
    }

    &__row {
        @include tablet {
            display: flex;
        }

        & + & {
            margin-top: 30px;
        }

        &--submit {
            #{$this}__col {
                text-align: right;
            }
        }
    }

    &__col {
        @for $i from 1 through 12 {
            &--#{$i} {
                @include tablet {
                    width: 100% * ($i / 12);
                }
            }
        }

        & + & {
            margin-top: 20px;

            @include tablet {
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }

    &__submit {
        align-self: flex-end;
        font-size: 18px;
        font-weight: 500;
        line-height: 1;

        .icon {
            margin-left: 10px;
        }
    }
}
