.form-control {
    $this: &;

    &__header {
        margin-bottom: 5px;
    }

    &__label {
        display: block;
        font-family: $ff-fira-code;
        font-size: 14px;
        font-weight: 500;
    }

    &__description {
        display: block;
        margin-top: 5px;
        font-size: 12px;

        @include tablet {
            font-size: 14px;
        }
    }

    &__content {
        &--flex {
            @include tablet {
                display: flex;
                align-items: center;
            }
        }
    }

    &__meta {
        overflow: hidden;
        margin-top: 10px;
        font-size: 14px;
        text-overflow: ellipsis;

        @include tablet {
            margin-top: 0;
            margin-left: 20px;
        }
    }
}
