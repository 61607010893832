.scroll-to-top {
    $this: &;

    position: fixed;
    right: 20px;
    bottom: 40px;
    overflow: hidden;
    z-index: 200;
    width: 56px;
    height: 56px;
    background-color: $color-yellow-2;
    border-radius: 50%;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.23);

    @include desktop {
        bottom: 20px;
    }

    @include desktop-wide {
        right: calc((100% - 1184px) / 2);
    }
}
