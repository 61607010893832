.board {
    $this: &;

    &__container {
        padding: 0 8px;

        @include tablet {
            padding: 0 15px;
        }

        @include desktop-wide {
            padding: 0;
        }
    }
}
