.page-overlay {
    $this: &;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    background-position: left 120%, right bottom, right bottom, center center;
    background-repeat: no-repeat, no-repeat, no-repeat, repeat-y;
    background-size: 70%, 30%, 0, cover;
    color: $color-white;
    transition: background-color 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    background-image: url(../img/snow-man.png), url(../img/gift.png), url(../img/tree.png),
        url('../img/bg.jpg');

    @include desktop {
        background-position: 10% bottom, 55% bottom, right bottom, center center;
        background-repeat: no-repeat, no-repeat, no-repeat, repeat-y;
        background-size: 300px, 250px, 350px, auto;
    }

    @include desktop-wide {
        background-size: auto, auto, auto, auto;
    }

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 30px;
    }

    &__content {
        text-align: center;
    }

    &__content-main {
        @include desktop {
            display: flex;
            align-items: center;
        }
    }

    &__title {
        color: $color-light-grey-4;
        font-family: $ff-chalkcyrillicfreehand;
        font-size: 144px;
        font-weight: normal;
    }

    &__subtitle {
        color: $color-white;
        font-family: $ff-lc-chalk;
        font-size: 24px;

        @include desktop {
            color: $color-yellow-4;
            font-size: 36px;
        }
    }

    &__progress-bar {
        margin: 20px 20px 0 20px;

        @include desktop {
            margin: 0 30px;
        }
    }

    &.is-hidden {
        opacity: 0;
        visibility: hidden;
    }
}
