.progress-bar {
    $this: &;

    position: relative;
    width: 300px;
    height: 27px;
    background-repeat: repeat-x;
    background-image: url('../img/zigzag.png');

    @include desktop {
        width: 380px;
    }

    &__line {
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: block;
        width: 0;
        height: 100%;
        background-image: url('../img/zigzag-yellow.png');
    }
}
