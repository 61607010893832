.expandable-media {
    $this: &;

    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: $color-darker-grey;

    &__icon {
        width: 15px;
        height: 15px;
        color: $color-white;
    }
}
