$color-white: #FFFFFF;
$color-black: #000000;
$color-dark-grey: #30353B;
$color-darker-grey: #333333;
$color-blue: #0078FC;
$color-blue-2: #0151EC;
$color-blue-3: #BADEF4;
$color-yellow: #FDDB64;
$color-yellow-2: #FFD541;
$color-yellow-3: #FDFF9D;
$color-yellow-4: #FDDB64;
$color-grey: #D8D8D8;
$color-light-grey: #FCFCFC;
$color-light-grey-2: #E5E5E5;
$color-light-grey-3: #EDEDED;
$color-light-grey-4: #CECDCD;
$color-dark-grey: #898989;
$color-green: #B1FA6C;
$color-pink: #FB788A;
$color-red: #CF0C0C;
