.header {
    $this: &;

    padding-top: 12px;
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;

    @include desktop-wide {
        padding-right: 0;
        padding-left: 0;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        transform: rotate(-15deg);
        transform-origin: right;
        color: $color-yellow;
        font-family: $ff-ds-eraser-cyr;
        font-size: 24px;

        @include tablet {
            font-size: 72px;
        }
    }

    &__title {
        color: $color-grey;
        font-family: $ff-ds-eraser-cyr;
        font-size: 36px;
        line-height: 1.27;

        @include tablet {
            font-size: 48px;
            line-height: 0.8;
        }
    }

    &__description {
        color: $color-white;
        font-family: $ff-lc-chalk;
        font-size: 20px;
        line-height: 1.55;

        @include tablet {
            font-size: 32px;
        }
    }

    &__sign-in {
        @include mobile-only {
            padding: 7px 10px;

            .icon {
                width: 18px;
                height: 18px;
            }
        }

        @include tablet {
            display: inline-flex;
        }
    }

    &__send-btn {
        position: fixed;
        bottom: 40px;
        left: 20px;
        z-index: 100;
        width: 56px;
        height: 56px;
        border-radius: 50%;

        @include tablet {
            position: static;
            display: inline-flex;
            width: auto;
            min-width: 242px;
            max-width: none;
            min-height: 56px;
            border-radius: 30px;
        }
    }
}
