/*
    general layout styles
    put .container here or other global layout classes

    example:

    .container {
        max-width: $containerMaxWidth;
        margin-left: auto;
        margin-right: auto;
    }

*/

*,
*::before,
*::after {
    box-sizing: border-box;
}

.html {
    position: relative;
    overflow-x: hidden;
    font-size: 100%;

    &.is-locked {
        overflow: hidden;
    }

    &.is-locked-touch {
        position: fixed;
        overflow-y: scroll;
        width: 100%;
        height: auto;
    }
}

.body {
    overflow-x: hidden;
    margin: 0;
    background: url('../img/bg.jpg') repeat-y fixed;
    background-size: 100%;
    font-family: $ff-fira-code;
    -webkit-font-smoothing: antialiased;
}

.wrapper {
    @include desktop {
        background-attachment: fixed;
        background-position: left top, right 140px, left bottom, 60% 90%;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
        background-size: 300px, 500px, 535px, 340px;
        background-image: url(../img/list.png), url(../img/tree.png), url(../img/snow-man.png),
            url(../img/gift.png);
    }
}

.container {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.text-center {
    text-align: center;
}

.img-bg {
    background-color: $color-grey;
    color: darken($color-light-grey, 30);

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        max-width: 100px;
    }
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
}

@each $size, $value in $breakpoints {
    .show-if-#{$size} {
        display: none;

        @media (--#{$size}) {
            display: block;
        }
    }
}

.hide-if {
    &-mobile {
        @include mobile-only {
            display: none !important;
        }
    }

    @each $size, $value in $breakpoints {
        &-#{$size} {
            @media (--#{$size}) {
                display: none !important;
            }
        }
    }
}
