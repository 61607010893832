.card {
    $this: &;

    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__media {
        @include objectFitImageParent();

        position: relative;
        display: block;
        flex-grow: 1;
        cursor: pointer;

        &::before {
            content: '';
            position: relative;
            z-index: -1;
            display: block;
            width: 100%;
            padding-bottom: 103%;
        }

        @include hover-focus {
            #{$this}__expandable-media {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__expandable-media {
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 10;
        pointer-events: none;
        transition: opacity 0.3s linear, visibility 0.3s linear;
    }

    &__media-wrapper {
        position: relative;
        padding: 26px 24px;
        background-color: $color-light-grey-3;
    }

    &__title {
        font-family: $ff-caveat;
        font-size: 36px;
        font-weight: 700;
        text-decoration: none;
    }

    &__link {
        text-decoration: none;
    }

    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 24px;
    }

    &__tape {
        position: absolute;
        z-index: 10;

        &--left {
            top: -12px;
            left: -10px;
        }

        &--right {
            top: -12px;
            right: -12px;
        }

        &--bottom {
            bottom: 70px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__text {
        flex-grow: 1;
        font-size: 16px;
        line-height: 1.625;
        white-space: pre-line;
    }

    &__signature {
        align-self: flex-end;
        font-family: $ff-caveat;
        font-size: 32px;
        font-weight: 700;
    }

    &__like {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    &--image {
        margin-top: 10px;
        margin-right: 8px;
        margin-left: 8px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

        @include desktop {
            margin-right: 0;
            margin-left: 0;
        }
    }

    &--sticker {
        min-height: 364px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    &--sticker-and-image {
        margin-top: 10px;
        margin-right: 8px;
        margin-left: 8px;

        @include desktop {
            margin-right: 0;
            margin-left: 0;
        }

        #{$this}__media-wrapper {
            padding-bottom: 90px;
        }

        #{$this}__content {
            left: -8px;
            min-height: 364px;
            margin-top: -80px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
    }

    &--green {
        #{$this}__content {
            background-color: $color-green;
        }
    }

    &--yellow {
        #{$this}__content {
            background-color: $color-yellow;
        }
    }

    &--pink {
        #{$this}__content {
            background-color: $color-pink;
        }
    }

    &--blue {
        #{$this}__content {
            background-color: $color-blue-3;
        }
    }
}
