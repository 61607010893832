.form-input-file {
    $this: &;

    position: relative;
    font-size: 12px;
    font-weight: 400;

    &__control {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
