@font-face {
    font-family: DSEraserCyr;
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/DSEraserCyr.woff2') format('woff2'),
        url('../fonts/DSEraserCyr.woff') format('woff');
}

@font-face {
    font-family: LCChalk;
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/LCChalk.woff2') format('woff2'), url('../fonts/LCChalk.woff') format('woff');
}

@font-face {
    font-family: 'Fira Code';
    font-display: swap;
    font-style: normal;
    src: url('../fonts/FiraCode-Regular.woff2') format('woff2'),
        url('../fonts/FiraCode-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Fira Code';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/FiraCode-Medium.woff2') format('woff2'),
        url('../fonts/FiraCode-Medium.woff') format('woff');
}

@font-face {
    font-family: Caveat;
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Caveat-Bold.woff2') format('woff2'),
        url('../fonts/Caveat-Bold.woff') format('woff');
}

@font-face {
    font-family: Pangolin;
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Pangolin-Regular.woff2') format('woff2'),
        url('../fonts/Pangolin-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Chalk cyrillic freehand';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Chalkcyrillicfreehand.woff2') format('woff2'),
        url('../fonts/Chalkcyrillicfreehand.woff') format('woff');
}
